export default [{
        header: 'Транзакції',
    },
    {
        title: 'Балансові',
        route: 'apps-balance-list',
        icon: 'DollarSignIcon',
    },
    {
        title: 'Паливні',
        route: 'apps-fuel-list',
        icon: 'TruckIcon',
    },
    {
        title: 'Інвойси',
        route: 'apps-invoice-list',
        icon: 'FileTextIcon',
    },
    {
        title: 'Рахунки',
        route: 'apps-accounts',
        icon: 'CreditCardIcon',
    }
]